import styled, { css } from 'styled-components';
import tw from 'twin.macro';
import Img from 'gatsby-image';

export const InstaFeedWrapper = styled.div`
	${tw`
   lg:max-w-screen-lg
   h-full
  p-12
  bg-leaf
  mx-auto
  flex flex-col
  `}
	min-height:350px;
	> h3 {
	}
`;

export const StyledPost = styled(Img)`
  w-full
`;

export const Stylediframe = styled.iframe`
	width: 100%;
	border: 0;
	min-height: 230px;
`;
