import React from 'react';
import { InstaFeedWrapper, StyledPost, Stylediframe } from './InstagramFeed.styles';
import { Heading3 } from '../../Typography/Typography.react';

const instaScript = async () => {
	await fetch('https://cdn.lightwidget.com/widgets/lightwidget.js');
};

const widgetURL = async () => {
	await fetch('https://cdn.lightwidget.com/widgets/b911660a0337521aa09f8c49b3eceb95.html');
};

const InstagramPost = props => {
	const fluid = props.localFile.childImageSharp.fluid;
	return <StyledPost fluid={fluid} />;
};

const InstagramFeed = ({ instaFeed }) => {
	return (
		<InstaFeedWrapper>
			<Heading3>Our Instagram Feed</Heading3>
			{instaScript && <script src={instaScript}></script>}
			<Stylediframe
				src="https://cdn.lightwidget.com/widgets/b911660a0337521aa09f8c49b3eceb95.html"
				scrolling="no"
				className="lightwidget-widget"
			/>
		</InstaFeedWrapper>
	);
};

export default InstagramFeed;
